import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import map from "lodash/map"

import { CtaGetDemo } from "components/cta"

import {
  HomeHeroOld,
  HomePipe,
  HomeCustomized,
  HomeIntegrations,
  HomeAboutUs,
  HomeValueProps,
  // HomeProcess,
  // HomeOrganizedFinances,
  // HomeVisualizeData,
  // HomeStayCompliant,
} from "../sections/cannabis"

import { HomeCustomers, HomeTestimonials } from "src/sections/home"

const IndexPage = ({ data: { cannabis } }) => {
  const meta = useMeta(cannabis)
  const {
    home_hero_old,
    home_value_props,
    home_supply_chain,
    home_stay_compliant,
    home_organized_finances,
    home_productivity,
    home_profitability,
    home_customized,
    home_integrations,
    home_customers,
    home_testimonials,
    home_about_us,
    cta_get_demo,
  } = useContentfulSection(cannabis.sections)

  const pipe = [
    home_supply_chain,
    home_stay_compliant,
    home_organized_finances,
    home_productivity,
    home_profitability,
  ]

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <HomeHeroOld {...home_hero_old} />
        <HomeValueProps {...home_value_props} />
        {map(pipe, (props) => (
          <HomePipe key={props.id} {...props} />
        ))}
        <HomeCustomized {...home_customized} />
        <HomeIntegrations {...home_integrations} />
        <HomeCustomers {...home_customers} />
        <HomeTestimonials {...home_testimonials} />
        <HomeAboutUs {...home_about_us} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  #home_supply_chain {
    --tg-max-width: 448px;
    --tg-hat-color: var(--cannabis);
  }
  #home_stay_compliant {
    --tg-max-width: 440px;
    --tg-hat-color: var(--cannabis);
  }
  #home_organized_finances {
    --tg-max-width: 440px;
    --tg-hat-color: var(--moss);
    .horizontalWrap_image {
      img {
        /* padding-left: 1px; */
      }
    }
  }
  #home_productivity {
    --tg-max-width: 440px;
    --tg-hat-color: var(--seafoam);
    .horizontalWrap_image {
      /* margin-bottom: -1px; */
    }
  }
  #home_profitability {
    --tg-max-width: 440px;
    --tg-hat-color: var(--shoreline);
    .horizontalWrap_image {
      /* margin-bottom: -1px; */
    }
  }
`

export const query = graphql`
  {
    cannabis: contentfulPage(pageId: { eq: "cannabis" }) {
      ...Page
    }
  }
`

export default IndexPage
